<template>
  <div class="page">
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>当前位置</el-breadcrumb-item>
        <el-breadcrumb-item>智能填报</el-breadcrumb-item>
        <el-breadcrumb-item>位次查询</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="info">
      <div><span>省份</span><span>河南</span></div>
      <div>
        <span>科类选择</span><span>{{ this.is_wenli == 1 ? "文科" : "理科" }}</span>
      </div>
      <div>
        <span>分数</span>
        <el-input v-model="score" style="width: 100px;" class="test" placeholder="请输入分数"
          @change="getLine()"></el-input>分
      </div>
      <div>
        <span>位次</span><span>{{ this.ranking }}名</span>
      </div>
    </div>

    <!-- 图标 -->
    <div class="rank">
      <div id="ranking" style="height: 500px; width: 1000px"></div>
    </div>

    <!-- 同分导航条 -->
    <div class="nav">
      <div :class="{ act: this.nav == 1 }" @click="choose(1)">
        2023年同分去向
      </div>
      <div :class="{ act: this.nav == 2 }" @click="choose(2)">
        2022年同分去向
      </div>
      <div :class="{ act: this.nav == 3 }" @click="choose(3)">
        2021年同分去向
      </div>
      <div :class="{ act: this.nav == 4 }" @click="choose(4)">
        2020年同分去向
      </div>
    </div>
    <div class="oldData">
      <span>换算后位次</span> <span> {{ oldScore.ranking }}</span>
      <span>换算后分数</span> <span>{{ oldScore.score }}</span>
    </div>
    <!-- 院校列表 -->
    <div class="collegeList">
      <CollegeList v-for="(item, i) in collegeData" :key="i" :item="item"></CollegeList>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import l_score from "@/assets/data/l_score.js";
import w_score from "@/assets/data/w_score.js";
import CollegeList from "@/components/CollegeList";

export default {
  name: "Ranking",
  computed: { ...mapState(["userInfo"]) },
  components: {
    CollegeList,
  },
  data() {
    return {
      is_wenli: 1,
      year: 2024,
      score: 320,
      ranking: 0,
      nav: 1,
      rankList: [],
      collegeData: [],
      oldScore: [],
      lineList: [],
    };
  },
  mounted() {
    this.score = this.userInfo.score;
    this.is_wenli = this.userInfo.is_wenli;
    this.ranking = this.userInfo.ranking;
    this.getLine();
    this.createEchart();
  },
  methods: {
    choose(e) {
      this.nav = e;
      this.oldScore = this.lineList[e];
      this.getCollegeList(e);
    },
    getLine() {
      this.$fecth
        .post("score/getLine", {
          is_wenli: this.is_wenli,
          score: this.score,
        })
        .then((res) => {
          this.lineList = res;
          this.oldScore = this.lineList[1];
          this.ranking = this.lineList[0].ranking;
          this.getCollegeList();
        });
    },
    // 创建折线图
    createEchart() {
      // 图表参数
      if (this.userInfo.is_wenli == 1) {
        this.schoolRanking = w_score["2024"];
      } else {
        this.schoolRanking = l_score["2024"];
      }
      let score_x = [];
      let score_y = [];
      Object.values(this.schoolRanking).map((e) => {
        score_x.push(e.score);
        score_y.push(e.num);
      });

      let echarts = require("echarts");
      let myChart = echarts.init(document.getElementById("ranking"));
      let lineScore = [];
      if (this.is_wenli != 1) {
        lineScore = [
          {
            xAxis: "185",
          },
          {
            xAxis: "396",
          },
          {
            xAxis: "511",
          },
        ];
      } else {
        lineScore = [
          {
            xAxis: "185",
          },
          {
            xAxis: "428",
          },
          {
            xAxis: "521",
          },
        ];
      }

      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // type: "cross",
          },
          formatter: function (data) {
            console.log(data)
            return (
              "分数为：" +
              data[0].axisValue +
              "<br/>" +
              "同分人数" +
              "：" +
              data[0].value
              // "位次" +
              // "：" +
              // data[0].ranking
            ); //将小数转化为百分数显示
          },
        },
        xAxis: {
          name: "分数",
          data: score_x.reverse(),
          axisLabel: {
            interval: function (index, value) {
              if (
                value == "0" ||
                value == "100" ||
                value == "200" ||
                value == "300" ||
                value == "400" ||
                value == "500" ||
                value == "600" ||
                value == "700" ||
                value == "750"
              ) {
                return true;
              }
              return false;
            },
          },
        },
        grid: {
          left: "5%",
          right: "5%",
        },
        yAxis: {
          name: "人数",
          splitLine: {
            show: true,
          },
        },
        series: [
          {
            name: "同分人数",
            type: "line",
            data: score_y.reverse(),
            lineStyle: {
              color: "#fe5943",
              width: 1,
            },

            markLine: {
              data: lineScore,
              silent: true,
            },
            areaStyle: {
              color: "#fbece5",
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    getCollegeList() {
      this.$fecth
        .post("score/getCollege", {
          is_wenli: this.is_wenli,
          score: this.oldScore.score,
          year: this.oldScore.year,
        })
        .then((res) => {
          this.collegeData = res;
        });
    },
  },
};
</script>

<style lang="less" scoped>
body html {
  height: 0;
}

.page {

  .info {
    display: flex;
    justify-content: left;

    div {
      margin-right: 25px;
      color: #999999;

      span:last-child {
        color: #3479f8;
        margin-left: 10px;
        border: 1px solid #3479f8;
        padding: 2px 14px;
        border-radius: 30px;
      }
    }
  }

  .nav {
    display: flex;
    justify-content: space-between;

    div {
      width: 33.3%;
      text-align: center;
      background: gray;
      padding: 5px 0;
      color: white;
    }

    .act {
      background: chocolate;
      color: white;
    }
  }

  .oldData {
    margin-top: 15px;
    padding-left: 3px;

    span:nth-child(even) {
      margin-left: 5px;
      margin-right: 25px;
      color: red;
    }
  }

  .collegeList {
    border-top: 1px solid #dfdfdf;
    margin-top: 15px;
  }
}
</style>

<style lang="less">
.test {
  margin-left: 15px;
  margin-right: 5px !important;
  width: 60px;

  .el-input__inner {
    line-height: 25px;
    height: 25px;
    border: 1px #3479f8 solid;
    border-radius: 30px;
  }
}
</style>
